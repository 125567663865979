import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { paths } from '../../_redux/_constants';
import { BtnText } from "../../components/BtnText";
import { TableMain } from "../../components/TableMain";
import { StatusDriverApplicants } from "../StatusDriverApplicants";
import { IconButtonSmall } from "../../icons/IconButtonSmall";
import { IconCopy } from "../../icons/IconCopy";
import { dateEdit } from "../../js/helpers";
import { Application } from "../../_redux/types";

// @ts-ignore
import sprite from "../../icons/sprite.svg";
import "./style.less";
import { handleCopy } from "../../helpers/clipboardHelper";
import { formatPhoneNumber } from "../../helpers/formatPhoneNumber";

export const ApplicantsListTable = ({drivers}) => {
  const navigate = useNavigate();

  function go_to_applicant(id: number) {
    navigate(`/${paths.APPLICANTS}/:${id}`);
  }
  const tableHeadList = [
    {
      columnName: 'name',
      text: 'Name',
      containerClass: 'col-2'
    },
    {
      columnName: 'status',
      text: 'Status',
      containerClass: 'col-1'
    },
    {
      columnName: 'email',
      text: 'Email',
    },
    {
      columnName: 'phone',
      text: 'Phone',
      containerClass: 'col-2'
    },
    {
      columnName: 'driverse_licence_expires',
      text: 'Driverse Licence Expires',
      containerClass: 'col-2'
    },
    {
      columnName: 'applied_date',
      text: 'Applied date',
      containerClass: 'col-2'
    },
  ]

  const tableColumns = {
    name: (itemData: Application) => {
      const fullName = itemData?.driver_first_name || itemData?.driver_last_name
        ? itemData?.driver_first_name + ' ' + itemData?.driver_last_name
        : itemData.driver_ssn
    return <BtnText
      submitText={fullName}
      handleClick={ () => {go_to_applicant(itemData?.id)}}
    />},
    status: (itemData: Application) => <StatusDriverApplicants
      className="driver-applicants-status"
      status={itemData?.applic_status}
    />,
    email: (itemData: Application) => <CopyableField value={itemData.email} />,
    phone: (itemData: Application) => {
      const formattedPhoneNumber = formatPhoneNumber(itemData.phone_number || '');
      return <CopyableField value={formattedPhoneNumber} />;
    },
    driverse_licence_expires: (itemData) => {
      let timeString = "_____";
      if(itemData.driver_licenses[0]) {
        const currentDateTime = Number(new Date(itemData.driver_licenses[0]?.expires));
        timeString = dateEdit(currentDateTime, false) as string;
      }
      return <div className="">
        <div className="invited">{timeString}</div>
      </div>
    },
    applied_date: (itemData: Application) => {
      const currentDateTime = Number(new Date(itemData?.create_time));
      const timeString = dateEdit(currentDateTime, true) as string;
      return <div className="date">
        <div className="">{timeString}</div>
      </div>
    },
  }

  return (
    <TableMain tableHeadList={tableHeadList} tableColumns={tableColumns} drivers={drivers}/>
  );
};

// Компонент для відображення копіюваного поля з іконкою
const CopyableField = ({ value }) => {
  const [copied, setCopied] = useState(false); 

  const handleCopyClick = () => {
    handleCopy(value); 
    setCopied(true); 

    setTimeout(() => {
      setCopied(false);
    }, 1500);
  };

  return (
    <div className="row mx-0 align-items-center">
      {value && (
        <IconButtonSmall
          classes={"col"}
          configuration="grey"
          icon={
            copied ? (
              <svg width="20" height="20" fill="green">
                <use href={sprite + "#icon-check"} />
              </svg>
            ) : (
              <IconCopy className="icon-copy cursor-pointer" color="#6B6D82" />
            )
          }
          stateProp="enabled"
          tooltipClassName="icon-button-small-instance"
          tooltipText="Copy"
          onClick={handleCopyClick}
        />
      )}
      <div className="col-auto text-wrapper-2 px-0">{value}</div>
    </div>
  );
};
